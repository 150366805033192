import React from "react"

import Layout from "../../components/layout"
import SEO from "../../components/seo"

const ProjectHelse = ({ data, location }) => {
  return (
    <Layout>
      <SEO title="Mohawk project, desarrollo web corporativa y ecommerce en Drupal" description="Desarrollo ecommerce con Drupal totalmente customizado, con facet search, integración diaria de productos y sincronización vía API externa."/>
  <section className="jumbotron pt-3 pb-6 mb-0" style={{backgroundColor: '#FFD0D0'}}>
    <div className="hero-zone container">
      <div className="hero-text row justify-content-md-center">
        <div className="col-lg-6">
          <h1 className="font-weight-bold diplay-4">Mohawk Connects</h1>
          <p className="h3">Desarrollo de sitio web para cliente lider en papelería.</p>
        </div>
      </div>
    </div>
  </section>
  <section className="pt-3 bg-light text-left" id="features">
    <div className="container pb-5">
      <div className="row text-left">
        <div className="col-md-8 col-sm-12">
          <span class="badge badge-pill badge-secondary p-2 mr-2 mb-4">Desarrollo Drupal</span>
          <br></br>
          <h2>
            <strong className="text-left">Puntos clave</strong>
          </h2>
          <p className="lead pt-4">
            <span className="texto-destacado">
              <strong>E-commerce totalmente customizado.</strong>
            </span>
            <br />asdasd
          </p>
          <p className="lead pt-4">
            <span className="texto-destacado">
              <strong>Facet search powered by SOLR.</strong>
            </span>
            <br />asdasd
          </p>
          <p className="lead pt-4">
            <span className="texto-destacado">
              <strong>Integración diaria de producto, sincronización via API externa.</strong>
            </span>
            <br />asdasd
          </p>
          <p className="lead pt-4">
            <span className="texto-destacado">
              <strong>Integración con proveedores de logística.</strong>
            </span>
            <br />asdasd
          </p>
          <p className="lead pt-4 pb-5">
            <span className="texto-destacado">
              <strong>Búsqueda por radio y código postal,</strong>
            </span>
            <br />asdasd
          </p>
        </div>
        <div className="col-md-3 offset-md-1 col-sm-12">
          <div className="colaboradores">
                <h2 className="pt-lg-5">
              <strong className="text-left pt-0">Colaboradores</strong>
            </h2>
          </div>
          <p className="pt-3 client-desc">En este proyecto colaboramos con <a href="https:avatarnewyork.com/" target="_blank" rel="noopener noreferrer">Avatar New York</a>, que llevaron la gestión del proyecto y del cliente.</p>
          <div className="cliente-proyecto">
            <h2 className="pt-5">
              <strong className="text-left pt-0">Cliente</strong>
            </h2>
          </div>
          <p className="pt-3 client-desc">Mohawk es una empresa estadounidense de cuarta generación. Fabricantes de papel de propiedad familiar, cuyo propósito es hacer que la impresión sea más bella, efectiva y memorable.</p>
          <div className="btn-project">
            <a className="btn mr-4 btn-lg btn-outline-primary" href="https://mohawkconnects.com/">Sitio web →</a>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section className="jumbotron pb-5 text-left bg-white pt-0 mt-n6" id="features">
    <div className="container">
      <div className="row text-left">
        <div className="col-md-12 col-12 col-sm-12">
          <h2 className="pt-4 pb-4">
            <strong>El proyecto en imágenes</strong>
          </h2>
        </div>
{/*         <div className="col col-sm-12">
            <img className="img-fluid pt-4 mx-auto d-block" src="/images/helse-drupal-1.png" />
        </div> */}
        <div className='embed-responsive embed-responsive-16by9'>
           <iframe className="project-video embed-responsive-item" title="Video" width={1200} height={674} src="https://player.vimeo.com/video/412514449?autoplay=1&loop=1&autopause=0&muted=1&background=1"></iframe>
        </div>
      </div>
    </div>
  </section>

    </Layout>
  )
}

export default ProjectHelse
